<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div v-if="!showGrid" class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="stockproductlist" ref="dt" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['name','mpn']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :lazy="false" :paginator="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <span class="mb-2">
                                <Button type="button" icon="pi pi-external-link" label="导出" @click="exportData()"
                                    class="p-button-outlined mr-2" />
                                <Button type="button" icon="pi pi-filter-slash" label="清除"
                                    class="p-button-outlined mr-2" @click="clearFilter1()" />
                                <Button type="button" icon="pi pi-microsoft" label="切换" class="p-button-outlined mr-2"
                                    @click="changeShowGrid('grid')" />
                            </span>
                            <span class="p-input-icon-left mb-2" style="width:28%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入产品名称或MPN模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;"
                        frozen></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :showFilterMenu="col.showFilterMenu" :showFilterMatchModes="col.showFilterMenu"
                        :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline || col.isimage" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data.mpn"
                                @click="openStockProductDetail(slotProps.data)" class="p-button-link oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                            <Image v-if="col.isimage" :src="getFilePatch(slotProps.data[col.field])" width="50"
                                preview />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div v-if="showGrid" class="gridcard">
                <DataView :value="stockproductgrid" :loading="loading" layout="grid" :paginator="true" :rows="18"
                    :sortOrder="sortOrder" :sortField="sortField">
                    <template #header>
                        <div class="grid grid-nogutter">
                            <div class="col-2.5" style="text-align:left">
                                <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label"
                                    placeholder="按库存排序" @change="onSortChange($event)" />
                            </div>
                            <div class="col-1" style="text-align:right">
                                <Button type="button" icon="pi pi-list" label="切换" class="p-button-outlined mr-2"
                                    @click="changeShowGrid('list')" />
                            </div>
                            <div class="col-8" style="text-align:right">
                                <span class="p-input-icon-left mb-2" style="width:30%">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters1['global'].value" @keyup.enter="customFilter();"
                                        placeholder="输入产品名称或MPN模糊查询" style="width: 100%" />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #grid="slotProps">
                        <div class="col-12 md:col-2">
                            <div class="product-grid-item card">
                                <div class="product-grid-item-top">
                                </div>
                                <div class="product-grid-item-content">
                                    <Image :src="getFilePatch(slotProps.data.url)" :alt="slotProps.data.name"
                                        width="100" preview />
                                    <div class="product-name"
                                        style="overflow: hidden;white-space:nowrap;text-overflow:ellipsis;"
                                        v-tooltip.top="{ value: slotProps.data.name, disabled: false }">
                                        {{slotProps.data.name}}</div>
                                    <div class="product-description"
                                        style="overflow: hidden;white-space:nowrap;text-overflow:ellipsis;"
                                        v-tooltip.top="{ value: slotProps.data.mpn, disabled: false }">
                                        MPN：{{slotProps.data.mpn}}</div>
                                    <div class="product-description"
                                        style="overflow: hidden;white-space:nowrap;text-overflow:ellipsis;"
                                        v-tooltip.top="{ value: slotProps.data.sku, disabled: false }">
                                        SKU：{{slotProps.data.sku}}</div>
                                    <div class="product-description">FOB：{{slotProps.data.fobprice}}</div>
                                    <div class="product-description">预定库存：{{slotProps.data.subscribequantity}}</div>
                                    <div class="product-description">可售库存：{{slotProps.data.quantity}}</div>
                                </div>
                                <div class="product-grid-item-bottom">
                                </div>
                            </div>
                        </div>
                    </template>
                </DataView>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="详细" v-model:visible="displayDetaile" :style="{width: '60vw'}" :modal="true">
        <TabView>
            <TabPanel header="入库信息">
                <DataTable :value="instoragelist" :metaKeySelection="false" dataKey="id" scrollable scrollHeight="360px"
                    responsiveLayout="scroll" :loading="loading" :lazy="true" :paginator="true" rows="50"
                    :totalRecords="instoragetotalRecords" :first="instoragefirst"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="instoragelistPage($event)">
                    <Column field="platformsku" style="min-width:130px" header="平台SKU" frozen></Column>
                    <Column field="platformmpn" style="min-width:130px" header="平台MPN"></Column>
                    <Column field="name" style="min-width:160px" header="产品名称">
                        <template #body="slotProps">
                            <label class="oneline"
                                v-tooltip.top="{ value: slotProps.data.name, disabled: false }">{{slotProps.data.name}}</label>
                        </template>
                    </Column>
                    <Column field="instorageorderno" style="min-width:130px" header="入库单号"></Column>
                    <Column field="receiptdate" style="min-width:120px" header="入库时间"></Column>
                    <Column field="instorageprice" style="min-width:120px" header="入库单价">
                        <template #body="slotProps">
                            <label>{{slotProps.data.instorageprice}}{{slotProps.data.instoragecurrencyname}}</label>
                        </template>
                    </Column>
                    <Column field="actualinstoragequantity" style="min-width:120px" header="入库数量"></Column>
                    <Column field="outstoragequantity" style="min-width:120px" header="出库数量"></Column>
                    <Column field="storageprice" style="min-width:120px" header="库存金额">
                        <template #body="slotProps">
                            <label>{{slotProps.data.storageprice}}{{slotProps.data.instoragecurrencyname}}</label>
                        </template>
                    </Column>
                </DataTable>
            </TabPanel>
            <TabPanel header="出库信息">
                <DataTable :value="outstoragelist" :metaKeySelection="false" dataKey="id" scrollable
                    scrollHeight="360px" responsiveLayout="scroll" :loading="loading" :lazy="true" :paginator="true"
                    rows="50" :totalRecords="outstoragetotalRecords" :first="outstoragefirst"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="outstoragelistPage($event)">
                    <Column field="orderid" style="min-width:160px" header="OrderId" frozen>
                        <template #body="slotProps">
                            <label class="oneline"
                                v-tooltip.top="{ value: slotProps.data.orderid, disabled: false }">{{slotProps.data.orderid}}</label>
                        </template>
                    </Column>
                    <Column field="name" style="min-width:130px" header="客户"></Column>
                    <Column field="buyercode" style="min-width:130px" header="BuyerCode"></Column>
                    <Column field="mpn" style="min-width:120px" header="MPN"></Column>
                    <Column field="itemcode" style="min-width:120px" header="ItemCode"></Column>
                    <Column field="productname" style="min-width:160px" header="产品名称">
                        <template #body="slotProps">
                            <label class="oneline"
                                v-tooltip.top="{ value: slotProps.data.productname, disabled: false }">{{slotProps.data.productname}}</label>
                        </template>
                    </Column>
                    <Column field="outquantity" style="min-width:130px" header="出库数量"></Column>
                    <Column field="unitprice" style="min-width:120px" header="单价"></Column>
                    <Column field="outprice" style="min-width:120px" header="出货均价"></Column>
                    <Column field="productprofit" style="min-width:120px" header="产品利润"></Column>
                    <Column field="depositamount" style="min-width:120px" header="定金金额"></Column>
                    <Column field="fulfillmentperunit" style="min-width:130px" header="FulfillmentPerUnit"></Column>
                    <Column field="total" style="min-width:120px" header="Total"></Column>
                    <Column field="salesdate" style="min-width:120px" header="销售日期"></Column>
                </DataTable>
            </TabPanel>
            <TabPanel header="预定信息">
                <DataTable :value="subscribelist" :metaKeySelection="false" dataKey="id" scrollable scrollHeight="360px"
                    responsiveLayout="scroll" :lazy="true" :loading="loading" :paginator="true" rows="50"
                    :totalRecords="subscribetotalRecords" :first="subscribefirst"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="subscribelistPage($event)">
                    <Column field="orderid" style="min-width:130px" header="OrderId" frozen></Column>
                    <Column field="name" style="min-width:130px" header="客户"></Column>
                    <Column field="buyercode" style="min-width:130px" header="BuyerCode"></Column>
                    <Column field="mpn" style="min-width:120px" header="MPN"></Column>
                    <Column field="itemcode" style="min-width:120px" header="ItemCode"></Column>
                    <Column field="productname" style="min-width:160px" header="产品名称">
                        <template #body="slotProps">
                            <label class="oneline"
                                v-tooltip.top="{ value: slotProps.data.productname, disabled: false }">{{slotProps.data.productname}}</label>
                        </template>
                    </Column>
                    <Column field="salesquantity" style="min-width:130px" header="销售数量"></Column>
                    <Column field="unitprice" style="min-width:120px" header="单价">
                        <template #body="slotProps">
                            <label>{{slotProps.data.unitprice}}{{slotProps.data.currencyename}}</label>
                        </template>
                    </Column>
                    <Column field="deliveredquantity" style="min-width:120px" header="已发货数量"></Column>
                    <Column field="surplusquantity" style="min-width:120px" header="待发货数量"></Column>
                </DataTable>
            </TabPanel>
        </TabView>
    </Dialog>
</template>
<style lang="scss" scoped>
    .gridcard {
        background: #ffffff;
        padding: 1rem;
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-bottom: 1rem;
    }

    .p-dropdown {
        width: 14rem;
        font-weight: normal;
    }

    .product-name {
        font-size: 1rem;
        font-weight: 700;
    }

    .product-description {
        // margin: 0 0 0 0;
        vertical-align: middle;
        text-align: left;
        font-weight: 500;
    }

    .product-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
    }

    .product-category {
        font-weight: 600;
        vertical-align: middle;
    }

    ::v-deep(.product-grid-item) {
        margin: .5rem;
        border: 1px solid var(--surface-border);

        .product-grid-item-top,
        .product-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .img {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin: 1rem 0;
        }

        .product-grid-item-content {
            text-align: center;
        }

        .product-price {
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

    .oneline {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import CommonJs from '../../../js/Common.js';
    import Image from 'primevue/image';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '725';
            const listcolumns = ref();
            return {
                confirm,
                currentMenuId,
                listcolumns
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                stockproductgrid: [],
                instoragelist: [],
                outstoragelist: [],
                subscribelist: [],
                butLoading: false,
                displayDetaile: false,
                displayQueryPlan: false,
                showGrid: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                selectedRow: ref(),
                currentRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                instoragetotalRecords: 0,
                instoragefirst: 0,
                outstoragetotalRecords: 0,
                outstoragefirst: 0,
                subscribetotalRecords: 0,
                subscribefirst: 0,
                listTitle: '仓库列表',
                selectedKey: {
                    725: true
                },
                expandedKey: null,
                stockproductlist: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '仓库操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                        label: '下架',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.updateProductStatus('1');
                        }
                    }, {
                        separator: true
                    }, {
                        label: '上架',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.updateProductStatus('0');
                        }
                    }, {
                        separator: true
                    }, {
                        label: '新建入库',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            window.location.href =
                                '/#/crmputinstorage/putinstoragedraftbox?createinstorage=1';
                        }
                    }, {
                        separator: true
                    }, {
                        label: '导出',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.exportData();
                        }
                    }]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                sortOrder: ref(),
                sortField: ref(),
                sortKey: ref(),
                sortOptions: [{
                        label: '库存高到低',
                        value: '!quantity'
                    },
                    {
                        label: '库存低到高',
                        value: 'quantity'
                    },
                ],
            }
        },
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            MessageTip.initMsg();
            this.initColumn();
            this.initFilters1();
            this.loadMenuTree();
            this.loadStockData();
            this.currentRow = ref();
            this.sHeight = (window.innerHeight - 280) + "px";
            this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
            this.minHeight = "height:" + (window.innerHeight - 132) + "px";
        },
        methods: {
            initColumn() {
                this.listcolumns = [{
                    field: 'mpn',
                    header: 'MPN',
                    bodyStyle: 'min-width:130px;left:0px',
                    headerStyle: 'min-width:130px;left:0px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.matchModes,
                    frozen: true,
                    sortable: true,
                    showFilterMenu: true,
                    islink: true,
                }, {
                    field: 'name',
                    header: '名称',
                    bodyStyle: 'min-width:180px',
                    headerStyle: 'min-width:180px',
                    showFilterOperator: false,
                    oneline: true,
                    filterMatchModeOptions: this.matchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: true,
                }, {
                    field: 'sku',
                    header: 'SKU',
                    bodyStyle: 'min-width:130px',
                    headerStyle: 'min-width:130px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.matchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: true,
                }, {
                    field: 'allquantity',
                    header: '总库存',
                    bodyStyle: 'min-width:120px',
                    headerStyle: 'min-width:120px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.numMatchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: true,
                    isnum: true,
                }, {
                    field: 'quantity',
                    header: '可售库存',
                    bodyStyle: 'min-width:120px',
                    headerStyle: 'min-width:120px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.numMatchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: true,
                    isnum: true,
                }, {
                    field: 'subscribequantity',
                    header: '预定库存',
                    bodyStyle: 'min-width:120px',
                    headerStyle: 'min-width:120px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.numMatchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: true,
                    isnum: true,
                }, {
                    field: 'processquantity',
                    header: '在产库存',
                    bodyStyle: 'min-width:120px',
                    headerStyle: 'min-width:120px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.numMatchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: true,
                    isnum: true,
                }, {
                    field: 'shipmentquantity',
                    header: '在途库存',
                    bodyStyle: 'min-width:120px',
                    headerStyle: 'min-width:120px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.numMatchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: true,
                    isnum: true,
                }, {
                    field: 'fobprice',
                    header: 'FOB',
                    bodyStyle: 'min-width:120px',
                    headerStyle: 'min-width:120px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.numMatchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: true,
                    isnum: true,
                }, {
                    field: 'productstatusname',
                    header: '状态',
                    bodyStyle: 'min-width:120px',
                    headerStyle: 'min-width:120px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.matchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: true,
                }];
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
                this.loadStockData();
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('704', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '已入库列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                // setTimeout(() => {
                //     this.loadData(1);
                //     this.loadStockData();
                // }, 10);
            },
            openStockProductDetail(v_row) {
                this.currentRow = v_row;
                this.loadinstorageData(1);
                this.loadoutstorageData(1);
                this.loadsubscribeData(1);
                this.displayDetaile = true;
            },
            instoragelistPage(event) {
                console.log(event);
                var page = event.page;
                this.loadinstorageData(page + 1);
            },
            outstoragelistPage(event) {
                console.log(event);
                var page = event.page;
                this.loadoutstorageData(page + 1);
            },
            subscribelistPage(event) {
                console.log(event);
                var page = event.page;
                this.loadsubscribeData(page + 1);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            changeShowGrid(v_type) {
                if (v_type == 'grid') {
                    this.showGrid = true;
                } else {
                    this.showGrid = false;
                }
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 0,
                    conditions: []
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'name,mpn',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{stockproductlist(where:" + JSON.stringify(listwhere) +
                    "){id name allquantity quantity processquantity shipmentquantity subscribequantity url mpn sku fobprice productstatusname storeid }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.stockproductlist = jsonData.data.stockproductlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            loadStockData() {
                this.loading = true;
                var listwhere = {
                    pageindex: 1,
                    pagesize: 0,
                    conditions: []
                };
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'name,mpn',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{stockproductlist(where:" + JSON.stringify(listwhere) +
                    "){id name quantity subscribequantity url mpn sku fobprice productstatusname storeid }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.stockproductgrid = jsonData.data.stockproductlist;
                    }
                });
            },
            loadinstorageData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'productid',
                        value: this.currentRow['id'],
                        operation: 'equals'
                    }, {
                        name: 'warehousedetail',
                        value: '',
                        operation: 'equals'
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{instorageproductlist(where:" + JSON.stringify(listwhere) +
                    "){id platformsku platformmpn name instorageorderno receiptdate instorageprice actualinstoragequantity outstoragequantity storageprice instoragecurrencyname }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.instoragelist = jsonData.data.instorageproductlist;
                        this.instoragetotalRecords = jsonData.page.allrecord;
                        this.instoragefirst = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            loadoutstorageData(v_page) {
                console.log(v_page);
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'productid',
                        value: this.currentRow['id'],
                        operation: 'equals'
                    }, {
                        name: 'category',
                        value: '3',
                        operation: 'notequals'
                    }, {
                        name: 'warehousedetail',
                        value: '',
                        operation: 'equals'
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{outstocklist(where:" + JSON.stringify(listwhere) +
                    "){id orderid name buyercode productname mpn itemcode salesquantity outquantity unitprice outprice productprofit depositamount fulfillmentperunit total salesdate outtime productid suiteoutmark }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.outstoragelist = jsonData.data.outstocklist;
                        this.outstoragetotalRecords = jsonData.page.allrecord;
                        this.outstoragefirst = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            loadsubscribeData(v_page) {
                console.log(v_page);
                console.log(v_page);
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'mpn',
                        value: this.currentRow['mpn'],
                        operation: 'equals'
                    }, {
                        name: 'finish',
                        value: '1',
                        operation: 'equals'
                    }, {
                        name: 'category',
                        value: '3',
                        operation: 'equals'
                    }, {
                        name: 'warehousedetail',
                        value: '',
                        operation: 'equals'
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{orderlist(where:" + JSON.stringify(listwhere) +
                    "){id orderid name buyercode mpn itemcode productname salesquantity unitprice deliveredquantity surplusquantity salesdate currencyename}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.subscribelist = jsonData.data.orderlist;
                        this.subscribetotalRecords = jsonData.page.allrecord;
                        this.subscribefirst = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
                this.loadStockData();
            },
            submitData() {},
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            onSortChange(event) {
                var value = event.value.value;
                var sortValue = event.value;

                if (value.indexOf('!') === 0) {
                    this.sortOrder = -1;
                    this.sortField = value.substring(1, value.length);
                    this.sortKey = sortValue;
                } else {
                    this.sortOrder = 1;
                    this.sortField = value;
                    this.sortKey = sortValue;
                }
            },
            getFilePatch(v_patch) {
                if (v_patch == '') {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            updateProductStatus(v_status) {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要修改产品');
                    return;
                }
                var productIds = [];
                for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                    productIds.push({
                        "id": this.selectedRow[k1]["id"],
                        "status": v_status
                    });
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{productlist(o:" + JSON.stringify(productIds) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.selectedRow = null;
                        this.loadData(1);
                        this.loadStockData();
                        MessageTip.successmsg('修改成功');
                    } else {
                        MessageTip.warnmsg('修改失败');
                    }
                });
            },
            exportData() {
                this.$refs.dt.exportCSV();
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
        },
        components: {
            'AppFooter': AppFooter,
            Image
        }
    }
</script>